import {
    Button, Card, Col, Form, Input, Row, Skeleton, Space,
} from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import Table from '../../components/table';
import { useGlsCountryPrices } from '../../hooks/GLS/useGlsCountryPrices';
import { IGlsPriceListItem } from '../../types/gls';
import { useCountries } from '../../hooks/useCountries';
import { useFetch } from '../../hooks/useFetch';
import { useForm } from '../../hooks/useForm';

function GlsCountryPricesPage() {
    const [form] = useForm();
    const formFetch = useFetch();
    const formSubmitFetch = useFetch();
    const { countries } = useCountries();
    const { deleteGlsCountryPrice, list } = useGlsCountryPrices();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { code } = useParams();

    useEffect(() => {
        formFetch.request({
            url: `/gls/countries/${code}/options`,
            method: 'get',
        }).then((response) => {
            if (response.success) {
                form.setFieldsValue(response.data);
            }
        });
    }, [code]);

    const onEditClick = (id: number) => navigate(`/gls/countries/price/${id}`);

    const onFormSubmit = (values: Record<string, any>) => {
        formSubmitFetch.request({
            url: `/gls/countries/${code}/options`,
            method: 'post',
            data: values,
        });
    };

    const onPlusClick = () => navigate(`/gls/countries/price/create?countryCode=${code}`);

    const actions = (_: any, row: IGlsPriceListItem) => (
        <Space>
            <Button icon={<EditOutlined />} type="primary" onClick={() => onEditClick(row.id)} />
            <Button icon={<DeleteOutlined />} type="primary" onClick={() => deleteGlsCountryPrice(row.id)} />
        </Space>
    );

    const extra = (
        <Button icon={<PlusOutlined />} type="primary" onClick={onPlusClick} />
    );

    const formCardTitle = countries.loading ? (
        <Skeleton.Input active size="small" />
    ) : countries.data.find((el) => el.value === code)?.label;

    return (
        <Space className="w-100" direction="vertical">
            <Card title={formCardTitle} loading={formFetch.loading}>
                <Form form={form} layout="vertical" onFinish={onFormSubmit}>
                    <Row>
                        <Col xs={24} md={12} lg={8}>
                            <Form.Item label={t('parcelshopsLink')} name="parcelshop_link">
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Button htmlType="submit" type="primary" loading={formSubmitFetch.loading}>
                        {t('save')}
                    </Button>
                </Form>
            </Card>
            <Card extra={extra} title={`GLS ${t('prices')}`}>
                <Table
                    loading={list.loading}
                    columns={[
                        {
                            dataIndex: 'country',
                            title: t('country'),
                        },
                        {
                            dataIndex: 'weight',
                            title: t('orderPackageWeight'),
                        },
                        {
                            dataIndex: 'price',
                            title: t('price'),
                        },
                        {
                            render: actions,
                            width: 100,
                        },
                    ]}
                    dataSource={list.data?.list}
                    pagination={{
                        current: list.data?.currentPage,
                        total: list.data?.total,
                    }}
                />
            </Card>
        </Space>
    );
}

export default GlsCountryPricesPage;
