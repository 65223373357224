import {
    HashRouter as Router,
    Routes,
    Route,
} from 'react-router-dom';
import AuthedRoutes from '../containers/authed-routes';
import { UserContextWrapper } from '../context/user';
import HomePage from '../pages/home';
import LoginPage from '../pages/login';
import OrdersPage from '../pages/orders';
import SettingsPage from '../pages/settings';
import WelcomePage from '../pages/welcome';
import ProfilePage from '../pages/profile';
import OrderPage from '../pages/order';
import ClientsPage from '../pages/clients';
import FinancesPage from '../pages/finances';
import LogsPage from '../pages/logs';
import ClientPage from '../pages/client';
import FinancePage from '../pages/finance';
import { useLanguage } from '../hooks/useLanguage';
import ServiceCommissionsPage from '../pages/commissions';
import ServiceCommissionFormPage from '../pages/commission-form';
import GlsCountryPricesPage from '../pages/GLS/country-prices';
import RusPostCodes from '../pages/RusPost/codes';
import GlsCountriesPage from '../pages/GLS/countries';
import GlsCountryFormPage from '../pages/GLS/country-form';
import GlsTransportationPrices from '../pages/GLS/transportation-prices';
import GlsTransportationPrice from '../pages/GLS/transportation-price';
import RusPostTypesPage from '../pages/RusPost/types';
import RusPostTypePage from '../pages/RusPost/type';
import RusPostTypePricePage from '../pages/RusPost/type-price';
import DeliveryTypeCommissions from '../pages/DeliveryType/commissions';
import DeliveryTypeCommissionForm from '../pages/DeliveryType/commission-form';
import CustomDuties from '../pages/Custom/duties';
import CustomDuty from '../pages/Custom/duty';
import GeneralSettings from '../pages/general-settings';
import RusPostManifest from '../pages/RusPost/mainfest';
import Warehouses from '../pages/Warehouses';
import WarehouseForm from '../pages/Warehouses/form';
import PromoCodesListPage from '../pages/PromoCodes/list';
import PromoCodeFormPage from '../pages/PromoCodes/form';
import RusPostFulfillment from '../pages/RusPost/fulfillment';
import RusPostFulfillmentForm from '../pages/RusPost/fulfillment-form';
import PredefinedOrderMembersPage from '../pages/PredefinedOrderMembers/list';
import PredefinedOrderMemberFormPage from '../pages/PredefinedOrderMembers/form';
import PurchaseFormPage from '../pages/Purchases/form';
import PurchaseItemFormPage from '../pages/Purchases/item-form';
import ChinaManifest from '../pages/china-manifest';
import OrderChangeHistoryPage from '../pages/order-change-history';
import RusPostWeights from '../pages/RusPost/weights';

function Language() {
    useLanguage(true);
    return null;
}

function AppRouter() {
    return (
        <UserContextWrapper>
            <Router>
                <Language />
                <Routes>

                    {/* Роутинг авторизации */}
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/welcome" element={<WelcomePage />} />

                    {/* Роутинг для авторизованных пользователей */}
                    <Route path="/" element={<AuthedRoutes />}>
                        <Route path="/" element={<HomePage />} />
                        <Route path="/profile" element={<ProfilePage />} />
                        <Route path="/settings" element={<SettingsPage />} />
                        <Route path="/orders" element={<OrdersPage />} />
                        <Route path="/order/:id" element={<OrderPage />} />
                        <Route path="/clients" element={<ClientsPage />} />
                        <Route path="/client/:id" element={<ClientPage />} />
                        <Route path="/finances" element={<FinancesPage />} />
                        <Route path="/finance/:id" element={<FinancePage />} />
                        <Route path="/logs" element={<LogsPage />} />
                        <Route path="/service-commissions" element={<ServiceCommissionsPage />} />
                        <Route path="/service-commissions/:id" element={<ServiceCommissionFormPage />} />
                        <Route path="/gls/countries" element={<GlsCountriesPage />} />
                        <Route path="/gls/countries/country/:code" element={<GlsCountryPricesPage />} />
                        <Route path="/gls/countries/price/:id" element={<GlsCountryFormPage />} />
                        <Route path="/gls/transportation-prices" element={<GlsTransportationPrices />} />
                        <Route path="/gls/transportation-price/:id" element={<GlsTransportationPrice />} />
                        <Route path="/ruspost/codes" element={<RusPostCodes />} />
                        <Route path="/ruspost/manifest" element={<RusPostManifest />} />
                        <Route path="/ruspost/fulfillment" element={<RusPostFulfillment />} />
                        <Route path="/ruspost/fulfillment/:id" element={<RusPostFulfillmentForm />} />
                        <Route path="/ruspost/upload-weights" element={<RusPostWeights />} />
                        <Route path="/delivery/types" element={<RusPostTypesPage />} />
                        <Route path="/delivery/type/:id" element={<RusPostTypePage />} />
                        <Route path="/delivery/type/:id/price/:priceId" element={<RusPostTypePricePage />} />
                        <Route path="/delivery/type/:id/commissions" element={<DeliveryTypeCommissions />} />
                        <Route path="/delivery/type/:id/commissions/:commissionId" element={<DeliveryTypeCommissionForm />} />
                        <Route path="/custom-duties" element={<CustomDuties />} />
                        <Route path="/custom-duties/:id" element={<CustomDuty />} />
                        <Route path="/general-settings" element={<GeneralSettings />} />
                        <Route path="/warehouses" element={<Warehouses />} />
                        <Route path="/warehouses/:id" element={<WarehouseForm />} />
                        <Route path="/promo-codes" element={<PromoCodesListPage />} />
                        <Route path="/promo-codes/:id" element={<PromoCodeFormPage />} />
                        <Route path="/predefined-order-members" element={<PredefinedOrderMembersPage />} />
                        <Route path="/predefined-order-members/:id" element={<PredefinedOrderMemberFormPage />} />
                        <Route path="/purchases/:id" element={<PurchaseFormPage />} />
                        <Route path="/purchases/:id/items/:itemId" element={<PurchaseItemFormPage />} />
                        <Route path="/china/manifest" element={<ChinaManifest />} />
                        <Route path="/order-change-history" element={<OrderChangeHistoryPage />} />
                    </Route>

                </Routes>
            </Router>
        </UserContextWrapper>
    );
}

export default AppRouter;
