import {
    Button, Card, Form, notification, Upload,
    UploadFile,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { UploadOutlined } from '@ant-design/icons';
import { UploadChangeParam } from 'antd/lib/upload';
import { useForm } from '../../hooks/useForm';
import { getRequiredFieldMessage } from '../../helpers/form';
import { useFetch } from '../../hooks/useFetch';

function RusPostWeights() {
    const fetch = useFetch();
    const [form] = useForm();
    const { t } = useTranslation();

    const onUploadChange = async (e: UploadChangeParam<UploadFile<any>>) => {
        if (e.event?.percent === 100) {
            const formData = new FormData();
            formData.append('file', e.file.originFileObj as Blob);
            const response = await fetch.request({
                url: '/ruspost/update-weights',
                method: 'POST',
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (response.success) {
                notification.success({
                    message: response.message,
                });
            }
        }
    };

    return (
        <Card title={t('uploadNewWeights')}>
            <Form form={form}>
                <Form.Item name="file" rules={[{ required: true, message: getRequiredFieldMessage() }]}>
                    <Upload type="select" onChange={onUploadChange} showUploadList={false}>
                        <Button icon={<UploadOutlined />}>
                            {t('uploadNewWeights')}
                        </Button>
                    </Upload>
                </Form.Item>
            </Form>
        </Card>
    );
}

export default RusPostWeights;
